/**
 * Utils
 */
import $ from 'jquery';

// ### Responsive tables
// Get all table elements inside the content container
// & create wrapper container around them
$('.main table').not('.responsive-enabled').wrap('<div class="table-container"/>');


// ### Responsive Videos
// @url https://foundation.zurb.com/sites/docs/responsive-embed.html
$('iframe[src*="youtube"]').wrap('<div class="responsive-embed responsive-embed--dailymotion widescreen"/>');
$('iframe[src*="vimeo"]').wrap('<div class="responsive-embed responsive-embed--vimeo widescreen"/>');
$('iframe[src*="dailymotion"]').wrap('<div class="responsive-embed responsive-embed--dailymotion widescreen"/>');
