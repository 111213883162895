import Swiper from 'swiper';

Drupal.behaviors.sliderClients = {
  attach: function(context, settings) {
    const sliderClientsContainer = document.querySelector('.js-slider-clients');

    if (sliderClientsContainer) {
      const sliderClients = new Swiper(sliderClientsContainer, {
      // Default parameters
        slidesPerView: 6,
        spaceBetween: 30,
        loop: true,
        speed: 600,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },

          // Responsive breakpoints
          breakpoints: {
              // when window width is >= 320px
              320: {
                  slidesPerView: 2,
                  spaceBetween: 10,
              },
              // when window width is >= 480px
              480: {
                  slidesPerView: 3,
                  spaceBetween: 30
              },
              // when window width is >= 640px
              640: {
                  slidesPerView: 4,
                  spaceBetween: 40
              }
          }
      });
    }
  }
};
