import $ from 'jquery';

Drupal.behaviors.espace_perso = {
  attach: function (context, settings) {
      if (settings.choosit_hacks && settings.choosit_hacks.selected_term) {
          var selected_choice = settings.choosit_hacks.selected_term;

          if (selected_choice > 0) {
              var links = $(".menu-block-4 > ul > li");
              $.each(links, function (index, value) {
                  $(value).removeClass('active-trail active');
                  if ($(value).find('a[href*="' + selected_choice + '"]').length > 0) {
                      $(value).addClass('active-trail active');
                  }
              });
          }
      }
  }
};
