Drupal.behaviors.responsiveMenu = {
  attach: function(context, settings) {
    // function to set multiple attributes to an element
    function setAttributes(elem) {
      for (var i = 1; i < arguments.length; i+=2) {
        elem.setAttribute(arguments[i], arguments[i+1]);
      }
    }

    // Init responsive menu
    const menu = document.querySelector('#menu-principal .menu.first');
    if (menu != null) {
      const submenus = menu.querySelectorAll('.menu.second');

      menu.classList.add('vertical', 'large-horizontal');
      submenus.forEach(submenu => {
        submenu.classList.add('vertical');
      });
      setAttributes(menu,
        'data-responsive-menu', 'drilldown large-dropdown',
        'data-auto-height', 'true',
        'data-animate-height', 'true',
        'data-parent-link', 'true'
      );

      // duplicate top-bar & put it on responsive menu on mobile
      const menuContainer = document.querySelector('#menu-principal .block-menu-block .content');
      const topMenu = document.querySelector('.top-bar .menu_header').cloneNode(true);

      menuContainer.appendChild(topMenu);

      // Toggle Open/Close
      const triggersMenu = document.querySelector('.js-responsive-menu-toggle');

      triggersMenu.addEventListener('click', function(ev) {
        ev.preventDefault();
        document.querySelector('body').classList.toggle('nav-open');
      });
    }
  }
};
