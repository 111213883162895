// Importing Foundation first
// ### Core
import $ from 'jquery';

// All Foundation modules
// import Foundation from 'foundation-sites'
// Custom selected Foundation modules
import './foundation';
import 'what-input';
window.addEventListener('DOMContentLoaded', () => {
  $(document).foundation();
});

// Import all your components here
// But don't code inside this file.
import './polyfill';
import './utils';

// Components
import './components/menu';
import './components/menu-espace-perso';
import './components/slider-clients';
import './components/fixedHeader';

// Enables HMR for JS files imported here
if (module.hot) {
  module.hot.accept();
}

// Signed by Choosit!
console.log(`%c Made with ❤️ by Choosit! `, `background-color: #009ee0; color: white;`);
